import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';

const App = ({ settings }) => (
  <div>
      <form action={settings.link} style={{ width: '95%' }} method="GET">
        <input type="hidden" name="page_id" value="101"/>
        <input class="searchbar"
               style={{ width: '70%', maxWidth: '400px' }}
               placeholder={settings.language === 'nl' ? "Domeinnaam" : "Domain name"}
               name="zoeken"/>
        <input class="searchbutton"
               style={{ width: '30%', maxWidth: '150px' }}
               type="submit"
               value={settings.language === 'nl' ? "Zoeken" : "Search"}/>
      </form>
    </div>
);

const targets = document.querySelectorAll('.sbw-root');
Array.prototype.forEach.call(targets, target => {
  const id = target.dataset.id;
  const settings = window.sbwSettings[id];
  ReactDOM.render(<App settings={settings} />, target)
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
